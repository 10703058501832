import React, { useState,useEffect } from 'react'
import {usePhysicalObjects} from '../MattoState'
import { Grid, Slider, Typography,Divider,TextField } from '@mui/material';
import { invalidate } from '@react-three/fiber';
import { PhysicalObjectInterface } from '../ts/app_interfaces';
import { MathUtils, Vector3 } from 'three';
import { dimensionsToStringFlipped, getDimensions} from '../libs/util';
import { produce } from 'immer';

export const ScaleRotationSelector = () => {

    const [valueLabelFormat, setValueLabelFormat] = useState("");
    const selectedPhysicalObjectKey = usePhysicalObjects(state=>state.selectedPhysicalObjectKey)
    const getSelectedPhysicalObject = usePhysicalObjects((state) => state.getSelectedPhysicalObject)
    const updateObjectState = usePhysicalObjects(state=>state.updateObjectState)
    const currentThreeJSObject = usePhysicalObjects( (state) => state.getCurrentThreeJSObject)
	const physicalObjects = usePhysicalObjects( (state) => state.physicalObjects)
    const updatePhysicalObject = usePhysicalObjects(state=>state.updatePhysicalObject)

    const selectedPhysicalObjectTemp =  getSelectedPhysicalObject(selectedPhysicalObjectKey)
    const [selectedPhysicalObject, setSelectedPhysicalObject] = useState(selectedPhysicalObjectTemp)

    //const scale = selectedPhysicalObject?.scale ?? [1,1,1]
    //const originalScale = selectedPhysicalObject?.originalScale ?? 1
    // const maxScale = originalScale * 5; 
    const maxScale = 3
    const minScale = 0.1; 
    const stepScale = 0.01;
    // const stepScale = scale[0] / 100;  
    const scaleLabel = "Object Scaling";
    const rotationLabel = "Object Rotation";
    const positionLabel = "Object Position";
    
    const [scalePercentValue, setScalePercentValue]= useState(100);
    // const [scalePercentValue, setScalePercentValue]= useState( Math.round( 100 * (scale[0]/maxScale)));
    const [scaleFactorValue, setScaleFactorValue]= useState(1);
    const [scaleValue, setScaleValue] = useState(new Vector3(1, 1, 1));
    const [rotationValue, setRotationValue] = useState(new Vector3(0, 0, 0));
    const [positionValue, setPositionValue] = useState(new Vector3(0, 0, 0));

    const updateOriginalDimensions = () => {


        const selectedPhysicalObject:PhysicalObjectInterface = getSelectedPhysicalObject(selectedPhysicalObjectKey);
        const dim = getDimensions(selectedPhysicalObjectKey);

        const originalScale = selectedPhysicalObject.scale;
        const dimensionData = new Vector3(Math.round(dim.x/originalScale[0]), Math.round(dim.y/originalScale[1]), Math.round(dim.z/originalScale[2]));          
        
        if(dimensionData.x === 0) {
            dimensionData.x = Math.round((dim.x/originalScale[0]) * 100) / 100;
        }

        if(dimensionData.y === 0) {        
            dimensionData.y = Math.round((dim.y/originalScale[1]) * 100) / 100;            
            //if(dimensionData.y == 0) dimensionData.y = 1;
        }

        if(dimensionData.z === 0) {
            dimensionData.z = Math.round((dim.z/originalScale[2]) * 100) / 100;
        }

        if(!dimensionData.equals(new Vector3())) {
            const newObj = produce(selectedPhysicalObject, draft => { draft.dimensionData = [dimensionData.x,dimensionData.y,dimensionData.z] })
            updatePhysicalObject(newObj)
            setScaleValue(dimensionData);
        }
    }

    const calculateScaleFactor = (currObjectScale, oldObjectScale, newScaleFactor) =>{
        if(!currObjectScale|| !oldObjectScale|| !newScaleFactor) return;
        const ratioL = currObjectScale[0] / oldObjectScale[0];
        const ratioW = currObjectScale[1] / oldObjectScale[1];
        const ratioH = currObjectScale[2] / oldObjectScale[2];
        if(ratioL > 0.001 && Math.abs(ratioL - ratioW) < 0.01 && Math.abs(ratioL - ratioH) < 0.01) {
          newScaleFactor *= ratioL;
        }
        return newScaleFactor;
    }

    const updateScaleFactor = (currObject, oldObject) => {
        const threeObj = selectedPhysicalObjectKey == null ? null : currentThreeJSObject()

        if(threeObj) {
            if(!threeObj?.userData) threeObj.userData = {}
            let newScaleFactor = threeObj.userData?.scaleFactor || 1;
            if(currObject && oldObject && currObject.key == oldObject.key) 
                newScaleFactor = calculateScaleFactor(currObject.scale, oldObject.scale, newScaleFactor);
    
            threeObj.userData.scaleFactor = newScaleFactor;
            
            setScaleFactorValue(newScaleFactor);
            const percentChange = Math.round(newScaleFactor * 100);
            setScalePercentValue(percentChange);
        }
    
    }

    const updateRotation = (currObject) => {
        if(currObject && currObject.rotation) {
            const currRotation = currObject.rotation.map((val) => {
                return Math.round(MathUtils.radToDeg(val));     
            });
            if(!equals(currRotation, rotationValue.toArray())) {
                setRotationValue(new Vector3(currRotation[0], currRotation[1], currRotation[2]));
            }
        }
    }

    const updateScale = (currObject) => {
        if(currObject.dimensionData) {
            // const originalDimension = currObject.dimensionData;
            // const currScale = currObject.scale;
            // const dimY = originalDimension[1] !== 0 ? originalDimension[1] : 1;
            // const updatedScale = new Vector3(Math.round(originalDimension[0] * currScale[0]), Math.round(dimY * currScale[1]), Math.round(originalDimension[2] * currScale[2]));
            const updatedScale = getDimensions(selectedPhysicalObjectKey);
            setScaleValue(new Vector3(Math.round(updatedScale.x), Math.round(updatedScale.y), Math.round(updatedScale.z)));
            setValueLabelFormat(dimensionsToStringFlipped(updatedScale, 0));
        }
    }

    const updatePosition = (currObject) => {
        if(currObject && currObject.position) {
            const currPosition = currObject.position.map((val) => {
                return parseFloat(val.toFixed(1));     
            });
            if(!equals(currPosition, positionValue.toArray())) {
                setPositionValue(new Vector3(currPosition[0], currPosition[1], currPosition[2]));
            }
        }
    }

    const equals = (a, b) => JSON.stringify(a) === JSON.stringify(b);

    useEffect(()=>{

        if (!selectedPhysicalObjectKey) return;

        const updatedSelectedPhysicalObject:PhysicalObjectInterface = getSelectedPhysicalObject(selectedPhysicalObjectKey) 
        if(!updatedSelectedPhysicalObject) return;
        updatePosition(updatedSelectedPhysicalObject);
        updateRotation(updatedSelectedPhysicalObject);
        updateScaleFactor(updatedSelectedPhysicalObject, selectedPhysicalObject);
        updateScale(updatedSelectedPhysicalObject);
        setSelectedPhysicalObject(updatedSelectedPhysicalObject);

        if (!updatedSelectedPhysicalObject.dimensionData || updatedSelectedPhysicalObject.dimensionData.length == 0) updateOriginalDimensions();

    },[physicalObjects]);

    const handleChangeCommitted = () => {
        //calling updateObjectState automatically syncs the threeJS rotation/scale/position values with the physcial object    
            updateObjectState(selectedPhysicalObject);
    }

    const handleScaleFactorChange = (e, newValue) => {
        const threeObj = selectedPhysicalObjectKey == null ? null : currentThreeJSObject()
        // const currScale = selectedPhysicalObject.scaleFactor ?? 1
        // let scaleFactor = newValue/currScale;        
        const multiplyFactor = newValue / scaleFactorValue;
        setScaleFactorValue(newValue);

        const percentChange = Math.round(newValue * 100);
        setScalePercentValue(percentChange);
        
        threeObj.scale.multiplyScalar(multiplyFactor);
        invalidate();
        // handleChangeCommitted();
    }

    const handleScalePercentChange = (e) => {
        const threeObj = selectedPhysicalObjectKey == null ? null : currentThreeJSObject()
        const percentValue = e.target.value;
        if(!percentValue || percentValue < 1|| isNaN(percentValue)) {
            const emptyString = percentValue.toString();
            setScalePercentValue(emptyString);
        } else {
            setScalePercentValue(percentValue);
            const newValue = percentValue / 100;
            const multiplyFactor = newValue / scaleFactorValue;
            threeObj.scale.multiplyScalar(multiplyFactor);
            setScaleFactorValue(newValue);
            handleChangeCommitted();
        }   
    }
    const handleScalePercentBlur = (e) => {
        const threeObj = selectedPhysicalObjectKey == null ? null : currentThreeJSObject()
        let percentValue = e.target.value;
        if(!percentValue || isNaN(percentValue) || percentValue < 1){
            percentValue = 1;
            setScalePercentValue(percentValue);
            const newValue = percentValue / 100;
            const multiplyFactor = newValue / scaleFactorValue;
            threeObj.scale.multiplyScalar(multiplyFactor);
            setScaleFactorValue(newValue);
            handleChangeCommitted();
        }
    }

    const axisToIndex = (axis) => {
        return (axis === 'x' ? 0 : (axis === 'y' ? 1 : 2)); 
    }

    const handleScale = (e, axis) => {
        const threeObj = selectedPhysicalObjectKey == null ? null : currentThreeJSObject() 
        const newValue = parseFloat(e.target.value)
        const currScale = scaleValue.clone();
        if(isNaN(newValue)) {
            const emptyString = newValue.toString();
            currScale[axis] = emptyString;
        } else {
            const originalDimension = selectedPhysicalObject.dimensionData[axisToIndex(axis)];
            let scaleChange;
            if(originalDimension != 0) {
                scaleChange = newValue / (originalDimension*1.1);
            } else {
                scaleChange = newValue / 1.1;
            }
            threeObj.scale[axis] = scaleChange;
            handleChangeCommitted();
            currScale[axis] = newValue;
        }
        setScaleValue(currScale);
    }

    const handleBlurScale = (e, axis) => {
        const threeObj = selectedPhysicalObjectKey == null ? null : currentThreeJSObject() 
        let newValue = parseFloat(e.target.value);
        if(isNaN(newValue) || newValue < 1){
            const originalDimension = selectedPhysicalObject.dimensionData[axisToIndex(axis)];
            newValue = 1;
            const scaleChange = newValue / (originalDimension * 1.1);
            threeObj.scale[axis] = scaleChange;
            const currScale = scaleValue.clone();
            currScale[axis] = newValue;
            setScaleValue(currScale);
            threeObj.scale[axis] = scaleChange
            handleChangeCommitted()
        }
    }

    const handleRotate = (e, axis) => {
        const threeObj = selectedPhysicalObjectKey == null ? null : currentThreeJSObject() 
        const newValue = parseFloat(e.target.value) 
        const currRotation = rotationValue.clone();
        if(isNaN(newValue)) {
            const emptyString = newValue.toString();
            currRotation[axis] = emptyString;
        } else {
            threeObj.rotation[axis] = MathUtils.degToRad(newValue);
            handleChangeCommitted();
            currRotation[axis] = newValue;
        }
        setRotationValue(currRotation);
    }

    const handleBlurRotate = (e, axis) => {
        const threeObj = selectedPhysicalObjectKey == null ? null : currentThreeJSObject() 
        let newValue = parseFloat(e.target.value)
        if(isNaN(newValue)){
            newValue = 0;
            const currRotation = rotationValue.clone();
            currRotation[axis] = newValue;
            setRotationValue(currRotation);
            threeObj.rotation[axis] = MathUtils.degToRad(newValue)
            handleChangeCommitted()
        }
    }

    const handlePosition = (e, axis) => {
        const { value } = e.target;
        // Allow initial '-' sign and empty input (temporary string states)
        if (value === '-' || value === '') {
            const currPosition = positionValue.clone();
            currPosition[axis] = value;
            setPositionValue(currPosition);
            return;
        }
        
        const newValue = parseFloat(value);
        if (!isNaN(newValue)) {
            const threeObj = selectedPhysicalObjectKey == null ? null : currentThreeJSObject();
            const currPosition = positionValue.clone();
            
            currPosition[axis] = newValue;
            if (threeObj) {
                threeObj.position[axis] = newValue;
                handleChangeCommitted();
            }
            setPositionValue(currPosition);
        }

    }

    const handleBlurPosition = (e, axis) => {
        const threeObj = selectedPhysicalObjectKey == null ? null : currentThreeJSObject() 
        let newValue = parseFloat(e.target.value)
        if(isNaN(newValue)){
            newValue = 0;
            const currPosition = positionValue.clone();
            currPosition[axis] = newValue;
            setPositionValue(currPosition);
            threeObj.position[axis] = newValue;
            handleChangeCommitted()
        }
    }

    return (
        <Grid container id = 'ScaleRotationSelector' item xs={12} style={{paddingTop:'0px'}} >
            {!selectedPhysicalObject?.isDynamic && !selectedPhysicalObject?.isStaticObject  && <Grid item xs={12} style={{paddingBottom:'2px',paddingTop:'15px'}} >
                <Divider />
            </Grid>}
            <Grid item xs={12} >
                <Typography style={{paddingLeft:'4px', marginBottom: '10px',paddingTop:'15px'}}>{scaleLabel}</Typography>
            </Grid>
            <ScaleSelector name='All sides' handleChange={handleScaleFactorChange} handleChangeCommitted={handleChangeCommitted}
                max={maxScale} min={minScale} step={stepScale} value={scaleFactorValue} valueLabelFormat={valueLabelFormat} 
                handleChangeTextBox={handleScalePercentChange} handleBlurTextBox={handleScalePercentBlur} valueTextBox={scalePercentValue} />
            
            <GeneralInput name='L' handleChange={(e) => handleScale(e, "x")} value={scaleValue.x} valueFormat='mm' styleTypo={endAdornmentScale} handleBlur={(e) => handleBlurScale(e, "x")}/>
            <GeneralInput name='W' handleChange={(e) => handleScale(e, "z")} value={scaleValue.z}  valueFormat='mm' styleTypo={endAdornmentScale} handleBlur={(e) => handleBlurScale(e, "z")}/>   
            <GeneralInput name='H' handleChange={(e) => handleScale(e, "y")} value={scaleValue.y} valueFormat='mm' styleTypo={endAdornmentScale} handleBlur={(e) => handleBlurScale(e, "y")}/>
            <Grid item xs={12} >
                <Typography style={{paddingLeft:'4px',marginBottom: '10px',paddingTop:'20px'}}>{rotationLabel}</Typography>
            </Grid>
            <GeneralInput name='X' handleChange={(e) => handleRotate(e, "x")} value={rotationValue.x} valueFormat='&#176;' styleTypo={endAdornmentRotate} handleBlur={(e) => handleBlurRotate(e, "x")} />
            <GeneralInput name='Y' handleChange={(e) => handleRotate(e, "y")} value={rotationValue.y} valueFormat='&#176;'  styleTypo={endAdornmentRotate} handleBlur={(e) => handleBlurRotate(e, "y")} />
            <GeneralInput name='Z' handleChange={(e) => handleRotate(e, "z")} value={rotationValue.z} valueFormat='&#176;'  styleTypo={endAdornmentRotate} handleBlur={(e) => handleBlurRotate(e, "z")} /> 
            <Grid item xs={12} >
                <Typography style={{paddingLeft:'4px',marginBottom: '10px',paddingTop:'20px'}}>{positionLabel}</Typography>
            </Grid>
            <GeneralInput2 name='X' handleChange={(e) => handlePosition(e, "x")} value={positionValue.x} valueFormat='mm' styleTypo={endAdornmentPosition} handleBlur={(e) => handleBlurPosition(e, "x")} />
            <GeneralInput2 name='Y' handleChange={(e) => handlePosition(e, "y")} value={positionValue.y} valueFormat='mm'  styleTypo={endAdornmentPosition} handleBlur={(e) => handleBlurPosition(e, "y")} />
            <GeneralInput2 name='Z' handleChange={(e) => handlePosition(e, "z")} value={positionValue.z} valueFormat='mm'  styleTypo={endAdornmentPosition} handleBlur={(e) => handleBlurPosition(e, "z")} /> 
        </Grid>
    )
}

const textFieldLabel={paddingTop:'0px',textAlign:'center',textTransform:'capitalize',overflow:'hidden', textOverflow:'ellipsis', marginRight: '3px'} as any
const allSidesText={paddingTop:'0px',width: '100%', textAlign:'center',overflow:'hidden', textOverflow:'ellipsis', fontSize: '10px'} as any
const endAdornmentScale = {fontSize: '7px', marginRight: '2px',marginTop:'3px',pointerEvents:'none'}
const endAdornmentRotate = {fontSize: '10px', marginRight: '2px',pointerEvents:'none'}
const endAdornmentPosition = {fontSize: '7px', marginRight: '2px',marginTop:'3px',pointerEvents:'none'}

const ScaleSelector = ({name, max=1,min=0, step=0.01, value=0, handleChange, handleChangeCommitted, valueLabelFormat, handleChangeTextBox,handleBlurTextBox,valueTextBox = 0}) => {
    return (
        <Grid container item xs={12} style={{maxWidth:'90%',paddingLeft:'21px',marginBottom:'10px'}} >
            <Grid container item xs={10} >
                <Typography style={allSidesText} variant="caption" >{name} </Typography> 
                <Slider name="scale-rotation-slider" style={{marginRight:'7px'}} size='small' step={step} max={max} min={min} value={value} onChange={handleChange} onChangeCommitted={handleChangeCommitted} 
                valueLabelFormat={valueLabelFormat} valueLabelDisplay="auto"   />  
            </Grid>
            <Grid container item xs={2} > 
                <TextField InputProps={{style:{height:'20px', width: '36px', fontSize: '9px', padding: '0px',marginTop:'17px',marginLeft:'6px'}, 
                    endAdornment: <Typography style={{fontSize: '8px', marginRight: '2px',pointerEvents:'none'}}>%</Typography>}} 
                    className='without-padding'
                    type="number"
                    name="scale-rotation-percentage"
                    onChange={handleChangeTextBox} 
                    onBlur={handleBlurTextBox}
                    value={valueTextBox}
                    onKeyPress={(event) => {
                        if (event?.key === '-' || event?.key === '+'|| event?.key === 'e'|| event?.key === '.') {
                          event.preventDefault();
                        }
                      }} 
                />
            </Grid>
        </Grid>
    )
}

const GeneralInput = ({name, handleChange, value=0, valueFormat, styleTypo, handleBlur}) => {
    return (
        <Grid container item xs={4} style={{maxWidth:'100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}} >
            <Typography style={textFieldLabel} variant="caption" sx={{fontSize: {xs:'8px',sm: '8px', md:'10px',xl:'10px'}}}>{name} </Typography> 
            <TextField 
                InputProps={{style:{height:'20px', width: '36px', fontSize: '9px', padding: '0px'},
                endAdornment: <Typography style={styleTypo}>{valueFormat}</Typography>}}
                className='without-padding'
                type="number"
                onChange={handleChange} 
                onBlur={handleBlur}
                value={value}
                name={name}
                onKeyPress={(event) => {
                    if (valueFormat =='mm' && (event?.key === '-' || event?.key === '+'|| event?.key === 'e'|| event?.key === '.')) {
                        event.preventDefault();
                    }
                    if (valueFormat =='&#176;' && (event?.key === 'e' || event?.key === '+')) {
                        event.preventDefault();
                        }
                    }}  
            />             
        </Grid>
    )
}

const GeneralInput2 = ({name, handleChange, value=0, valueFormat, styleTypo, handleBlur}) => {
    return (
        <Grid container item xs={4} style={{maxWidth:'100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}} >
            <Typography style={textFieldLabel} variant="caption" sx={{fontSize: {xs:'8px',sm: '8px', md:'10px',xl:'10px'}}}>{name} </Typography> 
            <TextField 
                InputProps={{style:{height:'20px', width: '36px', fontSize: '9px', padding: '0px'},
                endAdornment: <Typography style={styleTypo}>{valueFormat}</Typography>}}
                className='without-padding'
                type="number"
                onChange={handleChange} 
                onBlur={handleBlur}
                value={value}
                name={name}
                onKeyPress={(event) => {
                    if (valueFormat =='mm' && (event?.key === '+'|| event?.key === 'e')) {
                        event.preventDefault();
                    }
                    if (valueFormat =='&#176;' && (event?.key === 'e' || event?.key === '+')) {
                        event.preventDefault();
                        }
                    }}  
            />             
        </Grid>
    )
}