import { CollectionInterface, CollectionThumbnail,CollectionUserImages, CollectionUserMaterials, MeasuringCategory, ProfileInterface, validateCollection } from 'ts-interfaces'
import { create } from 'zustand'
import { deleteRecordAndFiles, firebaseDB, profilesDB, updateRecord, uploadFileToGCloud, userCollectionsDB } from '../../libs/firebase'
import { produce } from 'immer'
import { doc, getDocs, limit, orderBy, query, startAfter, where } from 'firebase/firestore'
import { getUserLocationFromIP,generateID, getUserLocationFromIPdata } from "../helper";
import { updateProfile } from '../../libs/util'


export interface LoginData {
  user: any
  providerId?: string
  _tokenResponse?: any
}

interface LoginState {
  profile: ProfileInterface | null
  showLogin: any
  collections: CollectionInterface[]
}


let globalLoginStateIsProcessing=false; 


export const useLoginState = create( (set:any, get:any) => ({
  profile:null,
  showLogin:null,
  collections:[],
  collectionsFullyLoaded: false,
  processing:false,
  firebaseDoc:null,

  setShowLogin: (showLogin: any)=> set({showLogin}),
  setProfile: (profile:any) => {
    set({processing:false})
    if (!profile?.id) { set({firebaseDoc:null,profile:null, collections:[], processing:false, collectionsFullyLoaded:false}) }
    else { set({profile:profile})}
  },
  setIncrementRenderCount: async () => {
    if (globalLoginStateIsProcessing==true) return;
    const profile = get().profile
    if (!profile) return;
    try {
      globalLoginStateIsProcessing=true;
      const newProfile:ProfileInterface |any = produce(profile, (draft:ProfileInterface) => {
        draft.renders = draft.renders ? draft.renders + 1 : 1
      })
      await get().updateAndSaveProfile(newProfile)
    }
    finally { globalLoginStateIsProcessing=false; }
  },  
  //replaces the createProfileIfNotExists function
  //only useful when we have loginData
  //most important thing.. if i end up createing a profile 
  //Only one scenario where this is a problem -> user signups, then logs out, then logs in really fast. 
  setOrCreateProfile: async (loginData:LoginData, mailingList=false) => {
    if (get().profile?.id || get().processing==true) return;
    if (globalLoginStateIsProcessing==true) return;
    set({processing:true})
    try {
      globalLoginStateIsProcessing = true
      const resp = await createProfileIfNotExists(loginData, mailingList)
      if (resp?.profile?.id) { set({ firebaseDoc:resp.ref,  profile:resp.profile}) }
      else { set({firebaseDoc:null,profile:null, collections:[], collectionsFullyLoaded:false}) }
      return resp;
    }
    finally { set({processing:false}); globalLoginStateIsProcessing=false; }
  },

  getUserProfile: async (loginData:LoginData) => {
    //console.log("me visit get user profile", get().profile?.id, globalLoginStateIsProcessing );
    if (get().profile?.id || get().processing==true) return;
    if (globalLoginStateIsProcessing==true) return;
    set({processing:true})
    try {
      globalLoginStateIsProcessing = true
      const resp = await getProfile(loginData)
      if (resp?.profile?.id) { set({ firebaseDoc:resp.ref,  profile:resp.profile}) }
      else { set({firebaseDoc:null,profile:null, collections:[], collectionsFullyLoaded:false}) }
      return resp;
    }
    finally { set({processing:false}); globalLoginStateIsProcessing=false; }
  },

  updateAndSaveProfile: async (profile:ProfileInterface, pic={}) => {
    return new Promise((resolve, reject) => {
      if (get().profile?.uid == null) reject("User not logged in")
      if (!get().firebaseDoc) reject("No profile reference found")
      if (get().processing==true) reject("Processing") 
      updateProfile(profile,pic, get().firebaseDoc as any).then(result=>{
        set({profile:result})
        resolve(result)
      }).catch(error=> { console.log("error ", error); reject(error) })
    })
  },

  getMoreCollections: async (limit=40) => {
    try {
      if (get().collectionsFullyLoaded===true || get().processing==true) return;
      if (get().profile?.uid == null) return;
      set({processing:true} )
      console.log("Getting more collections");
      const moreCollections = await getCollections(get().profile.uid, get().collections, limit)
      set(produce( (state:any) => {
        const mergeCollections =  [...state.collections, ...moreCollections]
        state.collections = mergeCollections.filter((v,i,a)=>a.findIndex(t=>(t.id === v.id))===i)
        if (moreCollections.length < limit) state.collectionsFullyLoaded = true
      }))
    }
    finally { set({processing:false}) }
  },
  addOrUpdateCollection : async (collection: CollectionInterface, newImage) => {
    if (get().profile?.uid == null) throw new Error("User not logged in")
    const newCollection:CollectionInterface = await updateOrSaveCollection(collection,newImage)
    set(produce( (state:any) => {
      const index = state.collections.findIndex((c:CollectionInterface) => c.id == newCollection.id) 
      if (index < 0) state.collections.unshift(newCollection)
      else  state.collections[index] = newCollection
    }))
  },
  deleteCollection : async (collection: CollectionInterface) => {
    if (get().profile?.uid == null) throw new Error("User not logged in")
    await deleteCollection(collection)
    set(produce(  (state:any) => {
      state.collections = state.collections.filter((c:CollectionInterface) => c.id != collection.id)      
    }))
  },
  toggleProductInCollection : async (collectionID: string, productId: string, productThumbnail:string,userImage:CollectionUserImages, userMaterial:CollectionUserMaterials) => {
    const collection = get().collections.filter((c:CollectionInterface) => c.id == collectionID)?.[0]
    if (!collection) throw new Error("Collection not found")
    if (collection.uid != get().profile?.uid) throw new Error("Collection does not belong to user") 

    const newCollection:CollectionInterface|any = produce(collection, (draft:CollectionInterface) => {
      if (!draft.products) draft.products = []

      if (productId) {
        if (draft.products.includes(productId)) {         
          draft.products = draft.products.filter((p:string) => p != productId) 
          const t =  draft.thumbnails?.filter((t:CollectionThumbnail) => t.id != productId)
          draft.thumbnails = t==null ? [] : t
        }
        else { 
          draft.products.push(productId) 
          if (!draft.thumbnails) draft.thumbnails = []
          draft.thumbnails?.push({id:productId, src:productThumbnail})
        }
      }
      else if (userImage) {
        if (!draft.userImages) draft.userImages = []
        if (draft.userImages.find(u => userImage.id == u.id)) {              
          draft.userImages = draft.userImages.filter((u:CollectionUserImages) => u.id != userImage.id)
          const t =  draft.thumbnails?.filter((t:CollectionThumbnail) => t.id != userImage.id)
          draft.thumbnails = t==null ? [] : t
        }
        else  {          
          draft.userImages.push(userImage)
          if (!draft.thumbnails) draft.thumbnails = []
          draft.thumbnails?.push({id:userImage.id, src:userImage.src})
        }
      }
      else if (userMaterial) {
        if (!draft.userMaterials) draft.userMaterials = []
        if (draft.userMaterials.find(u => userMaterial.id == u.id)) {              
          draft.userMaterials = draft.userMaterials.filter((u:CollectionUserMaterials) => u.id != userMaterial.id)
          const t =  draft.thumbnails?.filter((t:CollectionThumbnail) => t.id != userMaterial.id)
          draft.thumbnails = t==null ? [] : t
        }
        else  {          
          draft.userMaterials.push(userMaterial)
          if (!draft.thumbnails) draft.thumbnails = []
          draft.thumbnails?.push({id:userMaterial.id, src:userMaterial.src})
        }
      }
    })
    await updateOrSaveCollection(newCollection)
    set(produce( (state:any) => {
      const index = state.collections.findIndex((c:CollectionInterface) => c.id == collectionID) 
      state.collections[index] = newCollection
    }))
  }
}))


export async function getCollections (userID, collections:any, limitRecords: number) {
  const a:any = [userCollectionsDB, where("uid", "==", userID), orderBy("updatedAt","desc"), limit(limitRecords)]
  if (collections.length > 0) {
      a.splice(a.length-1, 0, startAfter(collections[collections.length-1].updatedAt))
  }
  const q = query.apply(this, a)
  const snapshot = await getDocs(q)
  return snapshot.docs.map(doc => doc.data())
}


async function updateOrSaveCollection(collection:CollectionInterface, newFileBlob=null): Promise<CollectionInterface>  {
  return new Promise(async (resolve, reject) => {
      validateCollection(collection)
      
      let url:any = null;
      if (newFileBlob) { url = await uploadImage(collection, newFileBlob) }
      const newCollection = produce(collection, (draft:CollectionInterface) => {
        // if (newFileBlob)  //commented it to delete the old image
          if(newFileBlob) draft.coverImage = url
      })
      updateRecord(collection.id, "usersMyCollection", newCollection).then(result=>{
          // console.log("Collection updated!", collection);
          resolve(newCollection as CollectionInterface);
      }).catch(error=> { console.log("error ", error); reject(error) })
    })
}

//a wrapper for async stuff
async function uploadImage(collection:CollectionInterface,blob) {
  return new Promise((resolve, reject) => {
    uploadFileToGCloud(`userImages/${collection.uid}`, `collection_cover_${generateID()}`, blob).then(url => resolve(url)) 
    .catch(error => reject(error));
  })
}

async function deleteCollection (collection: CollectionInterface) {
  return new Promise((resolve, reject) => {
    try {
      deleteRecordAndFiles(collection.id, "usersMyCollection")
      resolve(collection)
    }
    catch(e) {
      console.log("Error Deleting Collection!" + e)
      reject("Error Deleting Collection!" + e)
    }
  })
}

let globalProfileIsProcessing=false; 
const  createProfileIfNotExists = async (firebaseData,mailingList)=>   {
  try {
    console.log("Visiting craeteProfile if not exists");
    if(globalProfileIsProcessing==true) { return;  }
    globalProfileIsProcessing = true;
    if ( (!firebaseData?.user?.uid) || (!firebaseData?.user.email)) return null;   
    const uid = firebaseData.user.uid;
    const email = firebaseData.user.email;
    if (profilesDB==null) throw new Error("profilesDB is not defined")
    const q = query(profilesDB, where("uid", "==", uid));
    const querySnapshot = await getDocs(q);    
    console.log('querySnapshot', querySnapshot.docs.length);
    if(querySnapshot.metadata.fromCache) {
      globalProfileIsProcessing = false;
      return;
    }
    
    if (querySnapshot.docs.length > 0) { return {ref:querySnapshot.docs[0].ref, updated:false, profile:querySnapshot.docs[0].data() as ProfileInterface} }
    else {       
      console.log("Now I am creating a new profile record");
      let country:any = 'United States'
      try {       
        const resp:any= await getUserLocationFromIPdata(2000);  
        country = resp?.country_name || 'United States'
      }
      catch (err) { console.log('Could not get user location'); }      
      const isGoogle = firebaseData.providerId=='google.com'
      const first_name = isGoogle ? firebaseData?._tokenResponse?.firstName : ''; 
      const last_name = isGoogle ? firebaseData?._tokenResponse?.lastName : '';  
      if (!uid || !email ) { throw new Error("uid and email are required"); }
      if (uid?.length < 5 || email?.length < 3) { throw new Error("uid and email are required"); }  
  
      const measuringCategoryValue = country == 'United States'?  MeasuringCategory.IMPERIAL: MeasuringCategory.METRIC
      const profile:ProfileInterface = {id:generateID(), uid:uid, email:email,country:country,
        measuring_category:measuringCategoryValue,
        measuringCategory:measuringCategoryValue,
        renders:0,
        mailingList:mailingList,createdAt:new Date(), created_at:new Date()}
      if(first_name?.length > 1) profile['first_name'] = first_name;
      if(last_name?.length > 1) profile['last_name'] = last_name;
        //create ref here. 
      const firebaseRef = doc(firebaseDB as any, 'profiles', profile.id as string)
      const result = await updateProfile(profile,{},firebaseRef)
      return {ref:firebaseRef,  updated:true, profile:result as ProfileInterface}
      }
    }
    catch (e) {  } 
    finally { 
      globalProfileIsProcessing = false;
    }
}

const getProfile = async (firebaseData) => {
  try {
    if (globalProfileIsProcessing==true) { return;  }
    globalProfileIsProcessing = true;
    if ( (!firebaseData?.user?.uid) || (!firebaseData?.user.email)) return null;   
    const uid = firebaseData.user.uid;
    if (profilesDB==null) throw new Error("profilesDB is not defined")
    const q = query(profilesDB, where("uid", "==", uid));
    const querySnapshot = await getDocs(q);    
    if (querySnapshot.docs.length > 0) { return {ref:querySnapshot.docs[0].ref, updated:false, profile:querySnapshot.docs[0].data() as ProfileInterface} }
    else return null;
  }
  finally {
    globalProfileIsProcessing = false;
  }
}
