import  {create}  from 'zustand'
import { produce } from 'immer'
import {materialDefaults,deepClone, material50cm} from './libs/util'
import { Color } from 'three';
import { InspectorMode, PhysicalObjectInterface, SideBarMode  } from './ts/app_interfaces';
import { invalidate }  from '@react-three/fiber'

export const defaultCameraPosition = [1,0,0,0,0,0.0000010001554604510685,-0.9999999999994995,0,0,0.9999999999994995,0.0000010001554604510685,0,0,49.367843386243472,0.000015370232479014257,1]


export const useMattoState = create( (set:any) => ({
  post: { "UseMetalRougness":true, "BakeShadowsOnDrag":true,"InRealTime":true, "Ambient":true, "DepthOfField":false, "Bloom":false, "AdaptievPixelRatio":true, "bias": -0.00089, "normalBias":0,"radius":4.5, "samples":10},
  showGrid:false,
  inspectorMode:InspectorMode.Materials,
  sideBarMode:SideBarMode.Objects,
  modal:null,
  profile:{},
  controls:null,
  transformMode:null,
  selectedObjectIsChanging:false,
  isUploading:false,
  saveProjectNow: false,
  denyUpload:false,
  isStateDirty:false,
  userFavoritesData:{},
  favoriteSelected:false,
  copiedMaterialData:{},
  doAnimate:false,
  snackbarMessage:null,
  snackbarAutoHide:true,
  gpuData:null,
  userCollectionData:[],
  paintSearchCode:null,
  showSubscription :null,
  backgroundRemovalProcessing: false,
  pdfProcessing: false,
  setSnackbarMessage: (snackbarMessage, snackbarAutoHide=true)=> set({snackbarMessage, snackbarAutoHide}), 
  setBackgroundRemovalProcessing: (backgroundRemovalProcessing)=> set({backgroundRemovalProcessing}), 
  setPdfProcessing: (pdfProcessing)=> set({pdfProcessing}),
  setModal: (modal)=> set({modal}),
  setDoAnimate: (doAnimate)=> set({doAnimate}),
  setFavoriteSelected: (favoriteSelected)=>set({favoriteSelected}),
  setPaintSearchCode: (paintSearchCode)=>set({paintSearchCode}),
  setCopiedMaterialData:(copiedMaterialData)=>set({copiedMaterialData}),
  setInspectorMode: (inspectorMode) => set({inspectorMode}),
  setSideBarMode: (sideBarMode) => set({sideBarMode}),
  setControls: (controls) => set({controls}),
  setTransformMode: (transformMode) => set({transformMode}),
  setSelectedObjectIsChanging: (selectedObjectIsChanging) => set({selectedObjectIsChanging}),
  toggleGrid: () => set(state => ({ showGrid: !state.showGrid })),
  setProfile: (profile)=> set({profile}),
  setStateDirty: (isStateDirty)=> set({isStateDirty}),
  setUserFavoritesData: (userFavoritesData)=> set({userFavoritesData}),
  setUserCollectionData: (userCollectionData)=> set({userCollectionData}),
  setGpuData: (gpuData)=> set({gpuData}),
  setIsRendering: (isRendering)=> set({isRendering}),
  setShowSubscription: (showSubscription)=> set({showSubscription}),
  set: fn => set(produce(fn)),
}))

const projectDataDefaults = {
  projectId:null, userId:null,scene:'original',title:'Untitled',envmap:'studio',cameraMatrix:null,
  lighting:{ambient:0.25, directional:62, colorTemperature:7500,directionalIntensity:2.5,radius:3,samples:12, exposure:1.0},
  backgroundTexture:null, cameraPosition:null, controlsTarget:null
}
export const useProjectData = create( (set:any) => ({...projectDataDefaults, 
  ...{update: (d) => set(d)},
  ...{reset: () => set(projectDataDefaults)},
  ...{set: fn => set(produce(fn)),}}))
// //projectData.set( d => { d.lighting.ambient=1 } )

export const usePhysicalObjects = create( ( set:any,get:any) => ({
  physicalObjects:[],
  draggedObject:[], //object keys from objects they were dragged in (as opposed to from saved projects)
  selectedPhysicalObject:null,
  selectedPhysicalObjectKey: null,
  updateCount:0,
  lockedObjects:[],
  
  selectedMaterialndex:0,
  setSelectedMaterialndex: (selectedMaterialndex)=> set({selectedMaterialndex}),

  setSelectedPhysicalObjectKey: (selectedPhysicalObjectKey) =>  {
    set({selectedPhysicalObjectKey})
    set( (state) => ({ selectedPhysicalObject:get().getSelectedPhysicalObject(selectedPhysicalObjectKey) }))
  },  
  getUpdateCount: () => { return get().updateCount },
  getCurrentSelectedPhysicalObject: () => { 
    const k = get().selectedPhysicalObjectKey
    return k==null ? null : get().getSelectedPhysicalObject(k)
  },
  getSelectedPhysicalObject: (key) => {
    const physicalObjects = get().physicalObjects
    const index = physicalObjects.findIndex( (p) => p.key== key)
    return physicalObjects[index]
  },
  setPhysicalObjects: (physicalObjects) => {
    set({physicalObjects}),  //used to add/delete objects
    set( (state) => ({ 
      draggedObject:[],
      lockedObjects:[],
      selectedPhysicalObjectKey:null,
      selectedPhysicalObject:null }))
  },
  isObjectDraggedIn: (physicalObject) => {
    const draggedObject = get().draggedObject
    const index = draggedObject.findIndex( (objectKey) => objectKey == physicalObject.key)
    return index!=-1
  },
  isObjectLocked: (selectedPhysicalObjectKey) => {
    const lockedObjects = get().lockedObjects
    const index = lockedObjects.findIndex( (objectKey) => objectKey == selectedPhysicalObjectKey)
    return index!=-1
  },
  lockSelectedObject: () => {
    if (get().selectedPhysicalObjectKey==null) return;
    const key = get().selectedPhysicalObjectKey
    set(produce( (draft:any) => {
      draft.lockedObjects.push(key)      
    }))
  },
  unLockSelectedObject: () => {
    if (get().selectedPhysicalObjectKey==null) return;
    const key = get().selectedPhysicalObjectKey
    set( (state) => ({
      lockedObjects: state.lockedObjects.filter((objectKey) => objectKey !== key),
    }))
  },
  isSelectedDynamicMaterial: () => {
    const selectedPhysicalObject = get().selectedPhysicalObject
    if (selectedPhysicalObject==null) return false;
    if (selectedPhysicalObject.type == 'dynamic' && selectedPhysicalObject.mode=='material') return true
    else { return false; }
  },

  isSelectedObjectStatic: (scene=window.scene) => {
    const selectedPhysicalObject = get().selectedPhysicalObject
    if (selectedPhysicalObject==null) return false;
    if (selectedPhysicalObject.isStaticObject != undefined) return selectedPhysicalObject.isStaticObject
    else {
      const threeJSObj = scene.children.find( (child) => child.userData.key == selectedPhysicalObject.key)
      return !(threeJSObj?.children?.length===1);
    }
  },
  isSelectedObjectDynamic: () => {
    const selectedPhysicalObject = get().selectedPhysicalObject
    if (selectedPhysicalObject==null) return false;
    if (selectedPhysicalObject.type == 'dynamic') return true
    else {
      return false;
    }
  },
  isSelectedObjectPaint: () => {
    const selectedPhysicalObject = get().selectedPhysicalObject
    if (selectedPhysicalObject==null) return false;
    if (selectedPhysicalObject.type == 'paint') return true
    else {
      return false;
    }
  },
  addPhysicalObject:(physicalObject:PhysicalObjectInterface | any, draggedIn=false) => {  
    if (!physicalObject.materialData) {
      if (physicalObject.isStaticObject) {
        //no materialProps necessary
        physicalObject.materialData = {materialProps: {}}
      }
      else {
        const materialProps = deepClone(materialDefaults)      
        if (draggedIn==true) materialProps.color = (new Color(0xC5C5C5) as any).toJSON();        
        physicalObject.materialData = {materialProps: materialProps}      
      }
    }
    set(produce( (draft:any) => {
      draft.physicalObjects.push(physicalObject) 
      draft.selectedPhysicalObjectKey = physicalObject.key
      draft.selectedPhysicalObject = physicalObject
      draft.draggedObject.push( draggedIn ? physicalObject.key : null)      
    }))
  },
  updatePhysicalObject: (physicalObject) => {
    set (produce( (draft:any) => {
      const index = draft.physicalObjects.findIndex( (p) => p.key==physicalObject.key)
        draft.physicalObjects[index] = physicalObject;
        draft.selectedPhysicalObject = physicalObject
        draft.selectedPhysicalObjectKey = physicalObject.key
        draft.updateCount += 1
    }))
    
  },
  deleteSelectedObject: () => {
    if (get().selectedPhysicalObjectKey==null) return;
    const key = get().selectedPhysicalObjectKey
    set( (state) => ({
      selectedPhysicalObjectKey:null,
      selectedPhysicalObject:null,
      draggedObject: state.draggedObject.filter((objectKey) => objectKey !== key),
      lockedObjects: state.lockedObjects.filter((objectKey) => objectKey !== key),
      physicalObjects: state.physicalObjects.filter((physicalObject) => physicalObject.key !== key)
    }))
  },
  deleteObject: (key) => {
    set( (state) => ({
      selectedPhysicalObjectKey:null,
      selectedPhysicalObject:null,
      draggedObject: state.draggedObject.filter((objectKey) => objectKey !== key),
      lockedObjects: state.lockedObjects.filter((objectKey) => objectKey !== key),
      physicalObjects: state.physicalObjects.filter((physicalObject) => physicalObject.key !== key)
    }))
  },
  updateObjectState: (physicalObject, scene=window.scene, updateSelectedObject=true) => {
    if (!scene?.children) return;
    const threeJSObject = scene.children.filter( (child) => child.userData.key == physicalObject.key)[0]
    if (!threeJSObject) return;

    const newPhysicalObject:any = produce(physicalObject,draftState => {
      draftState.position=[threeJSObject.position.x,threeJSObject.position.y,threeJSObject.position.z]
      draftState.scale = [threeJSObject.scale.x,threeJSObject.scale.y,threeJSObject.scale.z]
      draftState.rotation = [threeJSObject.rotation.x,threeJSObject.rotation.y,threeJSObject.rotation.z]
    })

    set (produce( (draft:any) => {
      const index = draft.physicalObjects.findIndex( (p:any) => p.key==newPhysicalObject.key)
      draft.physicalObjects[index] = newPhysicalObject;
      if (updateSelectedObject===true) {
        draft.selectedPhysicalObject = newPhysicalObject
        draft.selectedPhysicalObjectKey = newPhysicalObject.key
      }
    }))    
  },
  updateAllObjectStates: (scene=window.scene) => {
    const physicalObjects = get().physicalObjects
    physicalObjects.forEach( (physicalObject) => {
      get().updateObjectState(physicalObject,scene)
    })
  },
  getThreeJSObject: (key,scene=window.scene) => {
    if (!key || scene==null) return null;
    const threeJSObject = scene.children.filter( (child) => child.userData.key == key)[0]
    return threeJSObject 
  },
  //window.scene.children[2].children[0].material.color
  getCurrentThreeJSObject: (scene=window.scene) => {
     const selectedPhysicalObjectKey = get().selectedPhysicalObjectKey    
     if (selectedPhysicalObjectKey==null || scene==null) return null;
     const threeJSObject = scene.children.filter( (child) => child.userData.key == selectedPhysicalObjectKey)[0]
     return threeJSObject 
  }
}))

if (!window.debug) window.debug = {};
if (!window.debug.invalidate) window.debug.invalidate = invalidate;
window.debug.getPhysicalObjects = () => { return usePhysicalObjects.getState() }
window.debug.getProjectData = () => { return useProjectData.getState()}
window.debug.getMattoState = () => { return useMattoState.getState()}
window.debug.currentObjectByName= (name) => {
  const objects:any =  usePhysicalObjects.getState()
  objects.setSelectedPhysicalObjectKey(objects.physicalObjects.find( p => p.name==name).key)
}
window.debug.getCurrentObject = () => {
  const objects =  usePhysicalObjects.getState()
  const currentObject = objects.getSelectedPhysicalObject(objects.selectedPhysicalObjectKey)
  return currentObject
}
window.debug.getCurrentThreeJSObject = () =>  {
  return usePhysicalObjects.getState().getCurrentThreeJSObject()
}
window.debug.setTestTextureForCurrentObject = (testMaterial=material50cm) => {
  const objects =  usePhysicalObjects.getState()
  const selectedPhysicalObject = objects.getSelectedPhysicalObject(objects.selectedPhysicalObjectKey)
  if (selectedPhysicalObject && testMaterial) {
    const updatedObject = produce(selectedPhysicalObject, draft=> {
      if (draft.materialData==null) draft.materialData={files:null,materialProps:null};
      if (testMaterial?.files) draft.materialData.files = testMaterial.files;
      if (testMaterial?.materialProps) draft.materialData.materialProps = testMaterial.materialProps;
    })
    objects.updatePhysicalObject(updatedObject)
  }
  else if (!testMaterial) {
    const updatedObject = produce(selectedPhysicalObject, draft=> {
      draft.materialData={files:null,materialProps:null};
      draft.materialData.materialProps = deepClone(materialDefaults)
    })
    objects.updatePhysicalObject(updatedObject)
  }
}
window.debug.updateCurrentObject= (key,value) => {
  if (!key || !value) return null;
  const objects =  usePhysicalObjects.getState()
  const currentObject = objects.getSelectedPhysicalObject(objects.selectedPhysicalObjectKey)
  if (currentObject) {
    const newObject = produce(currentObject,draft => {
      draft[key] = value
    })
    objects.updatePhysicalObject(newObject)
    console.log(newObject);
  }
}
window.debug.updateMaterialPropsInCurrentObject = (key,value) => {
  if (!key || !value) return null;
  const objects =  usePhysicalObjects.getState()
  const currentObject = objects.getSelectedPhysicalObject(objects.selectedPhysicalObjectKey)
  if (currentObject) {
    const newObject = produce(currentObject,draft => {
      draft.materialData.materialProps[key] = value
    })
    objects.updatePhysicalObject(newObject)
    console.log(newObject);
  }
}