import React, { useContext, useState,useEffect,useMemo,Suspense } from 'react'
import { useProjectData,usePhysicalObjects, useMattoState } from '../MattoState'
import { getDocByID } from '../libs/firebase'
import { produce } from 'immer'
import { AuthContext } from "../auth/Auth";
import { parseSearch, IsUrlShow, setProfileFirstAction } from '../libs/util'
import { isMobileOnly } from 'react-device-detect';
import { ModalMode, SideBarMode } from '../ts/app_interfaces';
import { useProgress } from '@react-three/drei'
import { ProfileFirstActions } from 'ts-interfaces'

const deleteKeys = ['active','color','compressSize','compressed','description','md5','priority','supplier','thumbnailSize','uncompressedSize','visible','updatedAt']

export const SceneStartup = () => {
    const { total,loaded } = useProgress()
    const setDoAnimate = useMattoState((state) => state.setDoAnimate);
    const [didAnimate,setDidAnimate] = useState(false)
    const gpuData:any = useMattoState((state) => state.gpuData);
    const setModal = useMattoState((state) => state.setModal);
    const setSideBarMode = useMattoState((state) => state.setSideBarMode);
    const doWelcome = window.location?.search.toLowerCase().includes('welcome') ? true : false 

    useEffect(() => { 
        if (doWelcome==true) setSideBarMode(SideBarMode.Templates)
    },[])

    useEffect(() => {
        const pathname = window.location.pathname
        if ( window.location.href.includes('noanimate')==true || didAnimate==true) return;
        else if (!(pathname.startsWith('/show')  || pathname.startsWith('/project/new_from'))) return;
        else if (gpuData?.fps < 60) { 
            console.log("Cannot animate, fps is ", gpuData?.fps); 
            setTimeout(() => { setDidAnimate(true);}, 2000);
        }
        else if (total > 20 && loaded==total)  {
            setDoAnimate(true)
            setTimeout(() => { setDidAnimate(true);}, 5000);
        }
    },[total,loaded])

    useEffect(() => {
        if (didAnimate==false) return;
        if (doWelcome==true) setModal(ModalMode.Welcome)

    },[didAnimate])
    return (null)
}

export const LoadScene = (props) => {
    const updateProjectData = useProjectData(state=>state.update)
    const resetProjectData = useProjectData(state=>state.reset)
    const setPhysicalObjects = usePhysicalObjects( (state) => state.setPhysicalObjects)
    const {currentUser} = useContext(AuthContext);
    const setSideBarMode = useMattoState((state) => state.setSideBarMode);
    const gpuData:any = useMattoState((state) => state.gpuData);

    const pathname = window.location.pathname
    const startAnimation = pathname.startsWith('/show')  || pathname.startsWith('/project/new_from')

    useEffect( () => {
        resetProjectData(); setPhysicalObjects([]);         
        let fromShared=false;
        let id = props?.id  
        const searchParams = new URLSearchParams(props.location.search);
        const isCollectionId = searchParams.get("collectionId"); 
        if (isCollectionId) {
            setSideBarMode(SideBarMode.Collections);  
        }
        if (id=='new') {            
            if(isMobileOnly) {
                alert("Mattoboard Creator is not supported on mobile device. Please try to start a project on a tablet or desktop!")
                window.location.replace('/boards');
                return;
            }            
            setProfileFirstAction(ProfileFirstActions.PROJECT_NEW).finally(() => {})
            return;
        }
        if (id=='new_from' || IsUrlShow()) {
            fromShared=true
            const search:any = parseSearch(props.location.search)
            if (search.id) id=search.id; 
        }
        if(id && isMobileOnly && props.location.pathname.includes("/project/")){            
            //alert("Mattoboard Creator is not supported on mobile device. Please try to start a project on a tablet or desktop!")
            setProfileFirstAction(ProfileFirstActions.WELCOME_MOBILE).finally(() => {})        
            let x = window.location.href.replace('project','show');            
            x = x + '?mobileWarning=true'
            window.location.replace(x)
        }   
        if (id) { 
            if(gpuData?.fps < 40 && !sessionStorage.getItem("fpsalertshown")){
            //alert("We are unable to detect your GPU on this computer. Your performance may be degraded on this device. Please contact support for more help")
            sessionStorage.setItem("fpsalertshown", "true");}
            loadProject(id, fromShared) 
        }
        else if (!id) {
            //window.location='/project/new'
            //history('/project/new'); 
            return; }  
        return () => { resetProjectData(); setPhysicalObjects([]) }
    },[])
    //useEffect( () => invalidate(), [projectData.envmap])

    async function  loadProject(projectId, fromShared=false) {
        const p:any = await getDocByID('projects',projectId)
        if (p.exists()==false) {
            console.log("Could not find doc ", projectId);
            window.location.replace('/project/new')
            //history('/project/new', { replace: true });
            return
        }
        else if (p.data().user !== currentUser?.uid && fromShared==false) {
            const x = window.location.origin + '/project/new_from?id=' + projectId;
            console.log("User does not own this project sending to ", x);
            window.location.replace(x)
            return
        }
        else {
            const objects: any = []
            const data:any = p.data() 
            data.physicalObjects.forEach( obj => {
                const nextObj:any = produce(obj, draft => {
                    deleteKeys.forEach(k => { delete draft[k] })
                })
                objects.push(nextObj)
            })                  
            setPhysicalObjects(objects)
            if (fromShared==true) {
                data.id=null;
                data.user = currentUser?.uid                
                data.projectData.projectId = null;
                data.projectData.userId = currentUser?.uid;
            }            
            // const zz = {...data.projectData, ...{lighting:data.lighting, backgroundTexture:data.backgroundTexture}}
            // console.log(zz);
            let backgroundTexture = data.backgroundTexture;
            if(data.backgroundTexture) {
                backgroundTexture = data.backgroundTexture?.materialData?.materialProps ? data.backgroundTexture  : 
                produce(data.backgroundTexture, draft => { draft.materialData = { 
                                                                files: data.backgroundTexture.files, 
                                                                materialProps: data.backgroundTexture.materialProps,
                                                                name:data.backgroundTexture.name,
                                                                id:data.backgroundTexture.id} })
            }
            
            updateProjectData({...data.projectData, ...{lighting:data.lighting, backgroundTexture:backgroundTexture,cameraPosition:data.cameraPosition,controlsTarget:data.controlsTarget,screenshot: data.files?.screenshot}})            

        }
    }

    if (startAnimation==true) return <SceneStartup/>
    else return (null)
}