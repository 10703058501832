import React, { useRef, useState,useEffect,useMemo } from 'react'
import useTextureLoader from '../libs/useTextureLoader'
import { RepeatWrapping,SRGBColorSpace  } from 'three';
import { materialDefaults,deepClone, cloudflareCDN } from '../libs/util';
import { useThree} from '@react-three/fiber'
import { produce } from 'immer'
import { MaterialData, MaterialFiles, MaterialProperties } from '../../../../packages/ts-interfaces';


interface PhysicalMaterialProps {
    material: MaterialData;
	textureRepeatModifier?:number
	background?:boolean
	scaledMesh?:boolean  // is the scale irregular, e.g. not default from the mesh size
}
const resolveTextureSize = (files,textureType='color',textureSize='thumbnail',size=1024,forceHighQuality,aspectRatio=1) => {

	if (!files) return;
	if (forceHighQuality) {
		console.log("Using highest quality textures");
		return files[textureType+'_original']
	}
	textureType = textureType=='color' && files['albedo_original'] ? 'albedo' : textureType
	const k = textureSize=='thumbnail' ? 'thumbnail' :  textureSize +'_'+ textureType
	if (files[k]) return files[k];
	else {
		const originalKey = textureType+'_original';
		if (files[originalKey]) {			
			if (files[originalKey].startsWith("data") || files[originalKey].startsWith("blob") ) return files[originalKey];
			else {
				// const x= cloudflareCDN(files[originalKey], 'height='+size+',format=auto')
				let x = ""
				if(aspectRatio < 1) {
					x = cloudflareCDN(files[originalKey], 'width='+size+',format=auto')
				} else {
					x = cloudflareCDN(files[originalKey], 'height='+size+',format=auto')
				}
				return x
			}
		}
	}
}

export const PhysicalMaterial = ({material,textureRepeatModifier=0,background,scaledMesh}:PhysicalMaterialProps) => {
	const files : (MaterialFiles | undefined) = material?.files
	const materials:MaterialProperties = produce({...materialDefaults, ...material?.materialProps }, draft=> {
		if  (background==true &&  (!draft.textureRepeat)) {		
			draft.textureRepeat=15
		}
	})

	const ref:any = useRef();
	const { scene, invalidate } = useThree()
	const forceHighQuality = window.location?.search.toLowerCase().includes('forcehighquality')
	const overrideSize = (file) =>  { return forceHighQuality ? file?.replace(/small_/, 'large_') : file; }

	const smallColorTexture = useTextureLoader(resolveTextureSize(files, 'color','thumbnail', 100,false))
	const largeColorTexture = useTextureLoader(resolveTextureSize(files, 'color', 'small', 512,forceHighQuality, material?.aspectRatio))
	const colorTexture:any = largeColorTexture || smallColorTexture;
	const alphaTexture = useTextureLoader(resolveTextureSize(files, 'alpha', 'small', 512,forceHighQuality))
	const normalTexture = useTextureLoader(resolveTextureSize(files, 'normal', 'large', 1024,forceHighQuality))
	const armTexture = useTextureLoader(resolveTextureSize(files, 'arm', 'small', 512,forceHighQuality))

	// const smallColorTexture = useTextureLoader(files?.thumbnail)
	// const largeColorTexture = useTextureLoader(overrideSize(files?.small_color))
	// const colorTexture = largeColorTexture || smallColorTexture;
	// const alphaTexture = useTextureLoader(overrideSize(files?.small_alpha))
	// const normalTexture = useTextureLoader(overrideSize(files?.large_normal))
	// const armTexture = useTextureLoader(overrideSize(files?.small_arm))
	const displacementTexture =null
	//const displacementTexture = useTextureLoader(files?.small_displacement)
	//const roughnessTexture = useTextureLoader(files?.small_roughness)
	//const metalnessTexture = useTextureLoader(files?.small_metal)
	//const ambientTexture = useTextureLoader(files?.small_ambient)	
	//const allTextures = [colorTexture,roughnessTexture,metalnessTexture,alphaTexture,normalTexture,displacementTexture,ambientTexture]
	const allTextures = [armTexture,colorTexture,normalTexture,alphaTexture,displacementTexture]
	
	const updateTextureWrapping = () => {
		allTextures.forEach( (t:any) => {	
			
			if (t?.repeat) {
				t.wrapS = RepeatWrapping;
				t.wrapT = RepeatWrapping;
				textureRepeatModifier = textureRepeatModifier == 0 || isNaN(textureRepeatModifier) ? 1 : textureRepeatModifier	
				t.repeat.x = t.repeat.y =  (materials.textureRepeat ?? 1) * (textureRepeatModifier ?? 1)		

				const ratio = t.source?.data?.width / t.source?.data?.height

				if (isNaN(ratio) || scaledMesh==true) return;
				else if (ratio != 1 ) {
					if (ratio > 1) { t.repeat.y = t.repeat.y * ratio }
					else { t.repeat.x = t.repeat.x / ratio }
				}
			}   
		})
	}
	useEffect(() => { updateTextureWrapping(); invalidate() },[material?.materialProps,textureRepeatModifier])

	useEffect(() => {               
        if (ref.current && colorTexture != null) {
			updateTextureWrapping()
			if (alphaTexture) { ref.current.alphaTest = 0.6 }
			else { ref.current.alphaTest = 0.0 }
			const meshGeometry = ref.current?.parent?.geometry
			if (armTexture && meshGeometry && (!meshGeometry.attributes.uv2)) {
				meshGeometry.attributes.uv2 = meshGeometry.attributes.uv.clone();
			}
            //colorTexture.encoding = sRGBEncoding
			colorTexture.colorSpace = SRGBColorSpace
            ref.current.map = colorTexture          
			//ref.current.aoMap = ambientTexture
			ref.current.aoMap  =   armTexture
            ref.current.normalMap = normalTexture   
      		ref.current.displacementMap = displacementTexture     
			ref.current.roughnessMap = armTexture
			ref.current.metalnessMap = armTexture			
			// ref.current.metalnessMap = files.small_metal ? armTexture : null;
			// ref.current.roughnessMap  = files.small_roughness ? armTexture : null;			
			ref.current.alphaMap = alphaTexture	         
			ref.current.envMap = scene.environment
        }
        else if (colorTexture==null && ref.current) {
        	ref.current.map = ref.current.normalMap = ref.current.aoMap = ref.current.roughnessMap = ref.current.metalnessMap = ref.current.displacementMap = ref.current.alphaMap =  null
        }
			
		ref.current.needsUpdate=true
		invalidate()
	},allTextures)
	return ( <meshPhysicalMaterial  {...materials as any} ref={ref}  /> )
}