import React, { useEffect,useState,useContext, useRef } from "react"
import {Bar} from '../auth/Bar'
import {Card,CardActionArea,CardContent,CardMedia,Typography,Grid,Container, Tooltip } from '@mui/material'
import friendlyTime from 'friendly-time';
import { AuthContext } from "../auth/Auth";
import {projectsDB,getUrlFromRef,deleteRecordAndFiles,getDocByID, uploadAndSave,updateRecord} from '../libs/firebase'
import SvgIcon from '@mui/material/SvgIcon';
import { useNavigate } from "react-router-dom";
import { query, where,getDocs, orderBy, limit, startAfter } from "firebase/firestore";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { deepClone, generateID, cloudflareCDN } from "../libs/util";
import { useInView } from 'react-intersection-observer';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import AddToDriveIcon from '@mui/icons-material/AddToDrive';
import { produce } from "immer";

const cardCss = { borderRadius:'10px' }
const mediaCssNewProjectCard = { height: 140, backgroundSize: 'contain'  }
const mediaCss = { height: 140 }


export const Projects = (props) => {
	const {currentUser} = useContext(AuthContext);
	const [data,setData]=useState([])
	const [numberOfProjects, setNumberOfProjects]=useState(0)
	const history = useNavigate();
	const rootProjectContainer = useRef()
	const [hasArchived,setHasArchived]=useState(false)
	const { ref, inView, entry } = useInView({root:rootProjectContainer.current,threshold: 0,});
	const [projectsLoaded, setProjectsLoaded]=useState(false)
	const [isLoading,setIsLoading]=useState(false)
	const showArchived = window.location.search.indexOf('archive') > 0
	const [lastUpdatedAt, setLastUpdatedAt] = useState(null)


	const loadItems = () =>{
		if (isLoading==false && projectsLoaded==false) {
			loadProjects()
		}
	}

	useEffect(() => {
		if (inView==true){
			loadItems()
		}		
	},[inView])

	const loadProjects = () => {
		if(!currentUser) return;
		if (projectsLoaded==true) return;
		setIsLoading(true)

		const a = [projectsDB,where("user", "==", currentUser.uid), orderBy('updatedAt',"desc"), limit(20)]
		if (data.length > 0) {
			// a.splice(a.length-1, 0, startAfter(data[data.length-1].updatedAt))
			a.splice(a.length-1, 0, startAfter(lastUpdatedAt))
		}
		const q = query.apply( null, a)
		getDocs(q).then(querySnapshot => { 
			setProjectsLoaded(querySnapshot.size == 0)
			const prevProjects = data
			setIsLoading(false)			
			const tempProjects = []
			setNumberOfProjects(numberOfProjects + querySnapshot.size)
			querySnapshot.forEach((doc) => { 
					const d = doc.data()
					if (d.archive==true) setHasArchived(true)
					if (d.archive==true && showArchived==false) { console.log('skip archived');}
					else {  tempProjects.push(d) }
				}
			)
			if(querySnapshot.docs.length > 0) setLastUpdatedAt(querySnapshot.docs[querySnapshot.docs.length-1].data().updatedAt)
			let updatedProjectList = [...prevProjects, ...tempProjects]
			if(updatedProjectList.length > 0)
			setData(updatedProjectList)
		})	
		.catch((error) => { console.log("Error getting documents: ", error); });

	}

	const handleClick = (project) => { history("/project/" + (project?.id || '')); }
	const handleDelete = (e,project) => {
		e.stopPropagation()
		console.log(project);
		updateRecord(project.id,'projects',{archive:true}).then( (result) => {
			const newData = data.filter(d => d != project)
			setData(newData)
		})
	}
	const handleUndelete = (e,project) => {
		e.stopPropagation()
		updateRecord(project.id,'projects',{archive:false}).then( (result) => {
			const newProject = produce(project, draft => {
				draft.archive = false
			})
			const newData = data.map(d => d.id == project.id ? newProject : d)
			setData(newData)
		})
	}
	const copyProject = (e,project,index) => {
		let newProjects = [...data]
		e.stopPropagation()
		e.preventDefault()
		const newId = generateID()
		const cloneProject = deepClone(project)
		cloneProject.id=newId;
		cloneProject.projectData.projectId=newId
		cloneProject.projectData.title = cloneProject.projectData.title + '_copy'
		uploadAndSave(cloneProject.id,'projects',currentUser.uid, cloneProject,).then(() => {
				console.log("Project duplicated!");
				newProjects.splice(index+1, 0, cloneProject);
				setData(newProjects)
		}).catch( (e) => {
				console.log("Error copying project! ", e);
		})
	}

	return (
		<Grid ref={rootProjectContainer} sx={{height:'100vh', overflowY:'scroll'}}>
		<Bar />
		<Container maxWidth={false}>
			<Grid container spacing={1} sx={{paddingTop:'30px'}}  >
				<Grid item xs={12} container spacing={4}>
				<Grid key={'new'} item xs={3} >
				<NewCard onClick={ () => handleClick() } />
				</Grid>
				{data.map((project, index) => (
						<Grid key={project.id} item xs={3} >

				<Card  style={cardCss} onClick={()=> handleClick(project) }>
				<CardActionArea>
					<CardMedia
					style={mediaCss}
					image= {cloudflareCDN(getUrlFromRef(project.files?.screenshot),'height=220,format=auto')}
					title="Project"
					/>
					<CardContent>
					<div style={{display:"flex"}}>
					<Typography  variant="subtitle2" component="h2" noWrap>
						{project.projectData.title || "Untitled"}
					</Typography>

					{project.archive != true && 
					<SvgIcon  onClick={(e) => { 
						if (window.confirm('Are you sure you wish to delete this project?')) handleDelete(e,project);
						else { e.stopPropagation() }
					}}
					
					style={{ color: "black" , width:15,height:15,paddingTop:'3px', paddingLeft:'3px'}}  >
					
					<path d="M9,3V4H4V6H5V19A2,2 0 0,0 7,21H17A2,2 0 0,0 19,19V6H20V4H15V3H9M7,6H17V19H7V6M9,8V17H11V8H9M13,8V17H15V8H13Z" />
					</SvgIcon>
					}

					{project.archive === true && 
						<Tooltip title="Restore" placement="top">
						<AddToDriveIcon onClick= { (e) => { 
							if (window.confirm('Are you sure you wish to undelete this project?')) handleUndelete(e,project);
							else { e.stopPropagation() }
						 } } sx={{ fontSize: 15, paddingTop:'4px', paddingLeft:'10px', paddingRight:'5px' }} />
						</Tooltip>
					}


					{project.archive==true && 
						<Typography variant="caption" color="textSecondary" component="p"> (Archived)</Typography>
					}

					{project.archive!=true && 
					<ContentCopyIcon onClick={ (e) => { 
							copyProject(e,project,index)
							//else { e.stopPropagation() }										
						}}
						sx={{ fontSize: 15, paddingTop:'4px', paddingLeft:'10px' }} />
					}

					</div>
					<Typography variant="caption" color="textSecondary" component="p">
					Updated  { friendlyTime( new Date(project.updatedAt)) }

					</Typography>
					</CardContent>
				</CardActionArea>
				</Card>
				</Grid>
				))}
				</Grid>
			</Grid>
			{projectsLoaded==false && numberOfProjects >=20 &&
				<Grid item 
				  sx={{position:'absolute', bottom: '10px', left: 0, right: 0, margin: '0 auto', width: '36px', cursor: 'pointer', '&:hover': {border: '1px solid #ccc' }}}
				  onClick={loadItems}>
					<KeyboardDoubleArrowDownIcon sx={{fontSize: 36, color: '#808080'}} />
				</Grid>
			}
			{hasArchived==true && showArchived==false &&	
				<Grid>			
				<a href='/projects/?archive=true'><Typography variant="caption" color="textSecondary" component="p">Show Your Archived Projects</Typography></a>
				</Grid>
			}
		</Container>
		<Grid key={'placeholder'} ref={ref} item xs={6}>
			<img draggable={false} src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAHElEQVQI12P4//8/w38GIAXDIBKE0DHxgljNBAAO9TXL0Y4OHwAAAABJRU5ErkJggg==" height={1} alt="Red dot" />
		</Grid>

		</Grid>
	)
}

const NewCard = (props) => {
	return (
	<Card  style={cardCss} onClick={()=> props.onClick(null) }>
	  <CardActionArea>
	  <CardMedia
	    style={mediaCssNewProjectCard}
	    image='/images/plus.jpg'
	    title="New Project"
	  />
	    <CardContent>
	      <Typography  variant="subtitle2" component="h2">
	      New Project
	      </Typography>
	      <Typography variant="caption" color="textSecondary" component="p">
	      Start a new project from scratch
	      </Typography>
	    </CardContent>
	  </CardActionArea>
	  </Card>
	 )
}
