import React, { useRef,useState,useMemo,Suspense,useEffect } from 'react'
import { useGLTF} from '@react-three/drei'
import {getUrlFromRef} from '../../libs/firebase.js'
import { useBVH } from '../../components/useBVH';
import { PhysicalObjectInterface  } from '../../ts/app_interfaces';
import { MaterialProperties } from '../../../../../packages/ts-interfaces/index.js';
import { MeshPhysicalMaterial } from 'three';
import { getPreset } from 'ui';

export const PaintGLTFObject  = (props) => {
	return ( <Suspense fallback={null} ><AsyncPaintGLTFObject {...props}  /></Suspense> )	
}
interface AsyncGLTFProps {
    physicalObject: PhysicalObjectInterface;
    size: string;
	textureRepeatModifier?:number
	onLoad: () => void;
}
export const AsyncPaintGLTFObject = ( {physicalObject, size='small',onLoad}:AsyncGLTFProps) => {	
	const gltfFile = physicalObject.mesh?.web_sized_glb || physicalObject.url || physicalObject.files?.[size] || physicalObject.files?.draco_normal
	const gltf:any =  useGLTF(getUrlFromRef(gltfFile))

	const gltfClone = useMemo(() => { 
		const paintObj = gltf.scene.clone();
		paintObj.traverse((m) => {
			if(m.isMesh) {
				m.material = m.material.clone();
			}
		});
		return paintObj;
	}, [gltf])

	const [loaded,setLoaded]=useState(false)
	const ref:any = useRef()
	useBVH(ref)

	//physicalObject.paintMaterials is legacy. 
	const paintMaterials = physicalObject.paintMaterials || physicalObject.materialData?.paintMaterials
	const paintMaterialsData:any = physicalObject.paintMaterialsData

	useEffect(() => {
		if (ref?.current && paintMaterials) {
			const props:Array<MaterialProperties> = paintMaterials			
			ref.current?.traverse(t => {     
				if (t.isMesh===true && t?.material?.name && t.material.name.indexOf('color_material') == 0) {

					const idx = parseInt(t.material.name.substr('color_material'.length))
					if (isNaN(idx) || idx < 1) return;
					let p:any = props[idx-1]

					if (paintMaterialsData?.[idx-1]?.preset) {
						const preset = getPreset(paintMaterialsData[idx-1].preset)
						if (preset?.materialProps)
							p = {...p, ...preset.materialProps}
					}
					if (isNaN(idx)===false && p) {
						if (t.material.type=='MeshStandardMaterial' && t.material.map==null ) {
							const name = t.material.name
							const normalMap = t.material?.normalMap?.clone()
							const rougnessMap = t.material?.roughnessMap?.clone()
							const map = t.material?.map?.clone()
							t.material = new MeshPhysicalMaterial()
							t.material.name=name
							if (normalMap) t.material.normalMap = normalMap
							if (rougnessMap) t.material.roughnessMap = rougnessMap
							if (map) t.material.map = map
						}
						makeTexturePropsDefault(t)

						t.material.color.setHex(p.color)
						
						if (p.sheenColor && t.material.sheenColor) t.material.sheenColor.setHex(p.sheenColor)
						if (p.sheen) t.material.sheen = p.sheen
						if (p.sheenRoughness) t.material.sheenRoughness = p.sheenRoughness
	
						if (p.emissive && t.material.emissive) t.material.emissive.setHex(p.emissive)
						if (p.emissiveIntensity) t.material.emissiveIntensity = p.emissiveIntensity
	
						if (p.clearcoat) t.material.clearcoat = p.clearcoat
						if (p.clearcoatRoughness) t.material.clearcoatRoughness = p.clearcoatRoughness
	
						if (p.ior) t.material.ior = p.ior
						
						if (p.reflectivity) t.material.reflectivity = p.reflectivity
						if (p.envMapIntensity) t.material.envMapIntensity = p.envMapIntensity
						t.material.metalness = p?.metalness ? p.metalness : 0.32;
						t.material.roughness = p?.roughness ? p.roughness : 0;
					}               
				}
			})
		}
		else if (ref.current && paintMaterials==null) {
			ref.current?.traverse(t => {    
				if (t.isMesh===true && t?.material?.name && t.material.name.indexOf('color_material') == 0) {
					if (t.material.color && !(t.name == "CardSwatch" && t.material.name.indexOf('color_material2') ==0)) t.material.color.setHex(0xb9a0c7)
				}
			})
		}
	}, [paintMaterials,paintMaterialsData])

	useEffect(() => {
		if (ref.current && loaded==false) {
			setLoaded(true)
			gltfClone.traverse( m => { if (m.isMesh) { m.castShadow=true; m.receiveShadow=true; }} )
			onLoad()
		}
	}, [ref])
	return (
		<primitive ref={ref} castShadow receiveShadow  object={gltfClone} dispose={null} />
	)
}

const makeTexturePropsDefault = (t) => {
	t.material.sheenColor =  0x000000
	t.material.sheen = 0.0
	t.material.sheenRoughness = 0.0
	t.material.emissive = 0x000000
	t.material.emissiveIntensity = 0.0
	t.material.clearcoat = 0.0
	t.material.clearcoatRoughness = 0.0
	t.material.ior = 1.5
	t.material.reflectivity = 0.5
	t.material.envMapIntensity = 1.0
	t.material.metalness = 0.32
	t.material.roughness = 0.0
	t.material.side = 2
	return t
}
