import { Box3,Vector3,Vector2,BoxHelper,Matrix4 } from 'three';
import { MeshBVH,INTERSECTED, NOT_INTERSECTED,CONTAINED } from 'three-mesh-bvh';

const EXPAND_TEST_BOX_Y = 0.15;
function collisionRules(opt) {
    let app ={}
    function getPhysicalMeshes(scene=window.scene) { 
        
        return scene.children.filter(s => s.userData.physicalMesh==true) ?? []
    }
    function updatePhysics(currentMesh,meshes=[],setOnTop=false) {
        //console.time("updatePhysics")        
        if (meshes.length==0 || currentMesh==null) return;        
        setCollisionBoxes(meshes)
        if(currentMesh.collisionBox==null) return 0;
        if (setOnTop==true) {
            const testBox= currentMesh.collisionBox.clone()              
            testBox.min.y = 0; testBox.max.y = 100
            const h = meshes.filter( o=>  o != currentMesh && o.collisionBox?.intersectsBox(testBox)==true)
                        .sort( (o,o2) => o2.collisionBox.max.y - o.collisionBox.max.y)[0]
            if (h) {       
                currentMesh.position.y = h.collisionBox.max.y
                currentMesh.updateMatrixWorld()
                setCollisionBoxes([currentMesh])                
            }
        }        
        const intersecObjects = isObjectAbove(currentMesh,meshes,0.1)
        const dropPoint = getDroppedPointBVH(currentMesh,intersecObjects)
        //        console.timeEnd("updatePhysics");
        return round( floorY(currentMesh) + (dropPoint > 0 ? dropPoint + 0.01 : 0))
    }
    const getHighestCollision = (currentMesh,meshes=[]) =>  { return getCollisionsSorted(currentMesh,meshes)[0]}
    const getCollisionsSorted = (currentMesh,meshes=[]) => {
      //  const newBox = expandBox(currentMesh.collisionBox)
        return meshes.filter( o=>  o != currentMesh && o.collisionBox?.intersectsBox(currentMesh.collisionBox)==true)
            .sort( (o,o2) => o2.collisionBox.max.y - o.collisionBox.max.y)
    }
    const expandBox = (collisionBox, margin=0.2) => {
        return collisionBox.clone().expandByVector(new Vector3(0,margin,0))
    }
    function isObjectAbove(currentMesh,meshes=[], p =0.4) {
        const testBox= currentMesh.collisionBox.clone()
        testBox.min.x = testBox.min.x + (p * (testBox.max.x - testBox.min.x))
        testBox.max.x = testBox.max.x - (p * (testBox.max.x - testBox.min.x))
        testBox.min.z = testBox.min.z + (p * (testBox.max.z - testBox.min.z))
        testBox.max.z = testBox.max.z - (p * (testBox.max.z - testBox.min.z))
        const minY = testBox.min.y
        testBox.min.y = testBox.max.y - 0.001
        testBox.max.y = testBox.max.y + ((testBox.max.y - minY) * 0.2)                     
        const insideBox = currentMesh.collisionBox.clone()
        insideBox.max.y = insideBox.max.y - ((insideBox.max.y - insideBox.min.y) * 0.2)   
        insideBox.min.y = 0

        /*
        1. first find all objects that are above the current mesh.
        2. Now check 
        */
        const x = [
            ...new Set([...getCollisionsSorted(currentMesh,meshes).filter( o=>  o != currentMesh && o.collisionBox.intersectsBox(testBox)==false), 
                ... meshes.filter( o=>  o != currentMesh &&  o.collisionBox?.intersectsBox(insideBox)==true)])]
        return x.filter(o => currentMesh.collisionBox.containsBox(o.collisionBox) ==false )

    }
    function doesObjectContain(currentMesh,meshes=[]) {        
        return meshes.filter( o => o != currentMesh && currentMesh.collisionBox.containsBox(o.collisionBox)).length >  0        
    }

    /*
        Currentmesh = the object that we are dragging
        collisionmeshes = every other object in the scene
        maxdepth = how many levels of objects to check. 
                Greater the depth we go more precision but at the cost of performance
      
        The goal is to find the point the lowest point where the currentmesh should be droppped.
        In some sense simulating a drop
        The Y value for the currentMesh is stretch to the floor, we are interested in all collisions that could occur here
        All of our meshes calculates the BVH geometry, the getBVH helper function finds this.
            
    */

    function getDroppedPointBVH(currentMesh, collisionMeshes=[],maxDepth=6) {
        if (collisionMeshes.length==0) return 0;
        const testBox= currentMesh.collisionBox.clone()              
        let dropPoint = 0
        testBox.min.y = 0
        testBox.max.y = testBox.max.y + EXPAND_TEST_BOX_Y;
        
        for (var i =0; i < collisionMeshes.length;i++) {
            if (collisionMeshes[i] == currentMesh) continue;
            if (collisionMeshes[i].collisionBox.intersectsBox(testBox)==false) continue;
            
            getBVH(collisionMeshes[i]).shapecast({
                intersectsBounds: (box, isLeaf, score,currentDepth) => {
                    box.applyMatrix4(collisionMeshes[i].matrixWorld)
                    box.min.y = box.min.y + 0.1 // add a small margin
                    if (isEdgeIntersection(box,testBox)) {
                    //if (box.intersectsBox(testBox) == true) {                        
                        if ( (dropPoint==0 || dropPoint < box.max.y) && (isLeaf || currentDepth > maxDepth)) {
                           // debugger                           
                           // console.log("Drop point is now ", box.max.y);
                            dropPoint = box.max.y
                        }
                        return INTERSECTED
                    }   
                    else return NOT_INTERSECTED;
                }
            })
        }
        return dropPoint
    }
    const isEdgeIntersection = (fragmentBox,sourceBox) => {
       // if (sourceBox.containsBox(fragmentBox)) return false;
        if (fragmentBox.intersectsBox(sourceBox)) {            
            //ok but where does it intersect? 
            return true;
        }
        else return false;
    }
    const round = (value,decimals=2) => value <=0.001 ? 0 :  Number(Math.round(value+'e'+decimals)+'e-'+decimals); 
    const getBVH = (object) =>  {  return object.geometry.boundsTree }
    const setCollisionBoxes = (objects=[]) => objects.forEach(m => { if (m.geometry) m.collisionBox = getBoundingBox(m) });
    const getBoundingBox = (object) => { 
        // const b = new Box3()
        // b.setFromObject(object)
        // const b2 = object.geometry.boundingBox.clone().applyMatrix4(object.matrixWorld)
        // console.log(b, b2);
        return object.geometry.boundingBox.clone().applyMatrix4(object.matrixWorld)
    }
    const floorY = (object) => {     
        if (!object?.geometry) return 0
        if (!object?.collisionBox) object.collisionBox = getBoundingBox(object)
        const box = object.collisionBox
        const center = new Vector3()
        box.getCenter(center)        
        const offset = object.position.y - center.y
        const floor = ((box.max.y - box.min.y) /2)  + offset
        return floor
     }
     const baseY = (object) => {     
        if (!object?.geometry) return 0
        if (!object?.collisionBox) object.collisionBox = getBoundingBox(object)
        const box = object.collisionBox
        const center = new Vector3()
        box.getCenter(center)        
        const floor = ((box.max.y - box.min.y) /2)
        return floor
     }
     Object.assign(app, {updatePhysics,getPhysicalMeshes,floorY,baseY})
    return app
}
export let collisions = new collisionRules({});

