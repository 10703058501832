import React, { useState,useContext,useEffect, useRef } from 'react'
import { AuthContext } from "../auth/Auth";
import {IconButton} from '@mui/material';
import { styled } from '@mui/system';
import {useMattoState} from '../MattoState'
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import { CollectionPopover } from '../studioxr/components/CollectionPopover';
import { useLoginState } from "../studioxr/auth/LoginState";

const bookmarkBorderIconCss = { fill: 'black' }
const bookmarkIconCss = { fill:'#bd80d3' }
const BookmarkIconButton = styled(IconButton)(() => ({
    height:'fit-content',
    position:'absolute',
    bottom: '0px',
    right: '0px',
	opacity: 1,
	'&:hover': { opacity: 0.8 }
}))
const BookmarkIconButtonInProperties = styled(IconButton)(() => ({
    height:'fit-content',
	opacity: 1,
}))


export const BookmarkButton =  (props) => {
	const [bookmark, setBookmark]=useState(false)
    const [popoverShown, setPopoverShown] = useState(false);
	const userBookmarksData = useMattoState((state) => state.userBookmarksData);
	const setUserBookmarksData = useMattoState((state) => state.setUserBookmarksData);
	const [popoverPosition, setPopoverPosition] = useState({x:0, y:0})
	const setShowLogin = useLoginState((state) => state.setShowLogin);
	let materialId = props.id
    const popoverRef = useRef();

	useEffect(() => {
		setBookmark(userBookmarksData?.materials?.[materialId] == true) 
	},[userBookmarksData,materialId])

	const bookmarkIconOnclick = (event) => {
		if (!props.currentUserUid) { setShowLogin('signup'); return; }

		if(!bookmark) {
			setPopoverShown(true); 
			let x = event.clientX;
			let y = event.clientY;
			setPopoverPosition({x:x,y:y});
		}
		// setBookmark(!bookmark)
	}

	return (
		<React.Fragment>
			{/* {bookmark &&
				<BookmarkIconButton onClick={bookmarkIconOnclick}>
					<BookmarkIcon fontSize="small" style={bookmarkIconCss} />
				</BookmarkIconButton>
			} */}
			{!bookmark && props.showBookmarkIcon &&
				<BookmarkIconButton data-testid={'bookmark-' + props.testId || null} className='bookmark' onClick={bookmarkIconOnclick}>
					<BookmarkBorderIcon fontSize="small" style={bookmarkBorderIconCss} />
				</BookmarkIconButton>
			}

			{props.showBookmarkIconInProperties &&
				<BookmarkIconButtonInProperties data-testid={'bookmark-' + props.testId || null} className='bookmark' onClick={bookmarkIconOnclick}>
					{!props.isBookmarked && <BookmarkBorderIcon />}
					{props.isBookmarked && <BookmarkIcon style={bookmarkIconCss} />}
				</BookmarkIconButtonInProperties>
			}

			{popoverShown && 
            <CollectionPopover 
				currentUserUid={props.currentUserUid}
                popoverRef={popoverRef}
				popoverPosition={popoverPosition}
                onClose={() => setPopoverShown(false)}
                product={props?.product} 
				userImage={props?.userImage}
				userMaterial={props?.userMaterial}
			/> 
        	}
		</React.Fragment>
	);	
}